import React, { useState } from "react"
import { motion } from "framer-motion"
import { navigate } from "gatsby"

// Components
import Button from "../Button"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  const [state, setState] = useState({})

  // Handle Change
  function handleInputChange(event: any) {
    const value = event.target.value
    setState({ ...state, [event.target.name]: value })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const form = event.target
    fetch("/contact", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <form
      name="contact"
      method="POST"
      action="/success"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}>
      <input type="hidden" name="form-name" value="contact" />
      <motion.input
        initial={{ scale: 1 }}
        whileTap={{ scale: [1, 1.3, 1] }}
        type="text"
        name="firstname"
        placeholder="Nom"
        onChange={handleInputChange}
        required
      />
      <motion.input
        initial={{ scale: 1 }}
        whileTap={{ scale: [1, 1.3, 1] }}
        type="text"
        name="secondname"
        placeholder="Prénom"
        onChange={handleInputChange}
        required
      />
      <motion.input
        initial={{ scale: 1 }}
        whileTap={{ scale: [1, 1.3, 1] }}
        type="email"
        name="email"
        placeholder="E-Mail"
        onChange={handleInputChange}
        required
      />
      <motion.input
        initial={{ scale: 1 }}
        whileTap={{ scale: [1, 1.3, 1] }}
        type="text"
        name="subject"
        placeholder="Sujet"
        onChange={handleInputChange}
        required
      />
      <motion.textarea
        initial={{ scale: 1 }}
        whileTap={{ scale: [1, 1.3, 1] }}
        name="message"
        cols={30}
        rows={10}
        placeholder="Message"
        onChange={handleInputChange}
      />
      <Button type="submit" color="accent">
        Envoyer
      </Button>
    </form>
  )
}

export default ContactForm
