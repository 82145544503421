import React from "react"
import { motion } from "framer-motion"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowCircleRight,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons"

interface Props {
  color: "accent" | "dark" | "light" | "transparent" | "theme"
  type: "submit" | "button" | "reset"
  children: string
  icon?: any
  onClick?: any
  style?: object
}

const Button = (props: Props) => {
  function handleIcons(icon: any) {
    if (props.icon === "arrow") {
      return faArrowCircleRight
    } else if (props.icon === "paper") {
      return faPaperPlane
    }
  }

  return (
    <motion.button
      style={props.style}
      type={props.type}
      onClick={props.onClick}
      className={`button button--${props.color}`}
      whileTap={{ scale: 0.9 }}>
      {props.children}
      {props.icon ? (
        <FontAwesomeIcon
          icon={handleIcons(props.icon)}
          className="fa-fw"
          transform="right-5"
        />
      ) : (
        ""
      )}
    </motion.button>
  )
}

export default Button
