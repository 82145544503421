import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Title from "../components/Layout/Title"
import ContactForm from "../components/Form/ContactForm"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPalette,
  faFileCode,
  faDesktop,
  faTasks,
} from "@fortawesome/free-solid-svg-icons"

const Contact = () => {
  return (
    <Layout flexGrow={true}>
      <SEO title="Contact" />
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          paddingBottom: "30px",
        }}>
        <div className="columns--2">
          <div className="column">
            <Title upper="Restons en" lower="Contact" />
            <p>
              Je suis à votre disposition pour toute question ou demande de
              devis pour votre projet. Je reprendrai contact avec vous dans les
              plus brefs délais afin d’évaluer vos besoins.
            </p>
            <p>
              Vous pouvez me joindre via ce formulaire ou vous pouvez également
              me contacter via certains réseaux mentionnés ci-dessous.
            </p>
            <p>
              Au plaisir de travailler avec vous et réalisons ensemble vos rêves
              les plus fous !
            </p>
            {/* <ul style={{ margin: 0, padding: 0 }}>
              <li style={{ color: "var(--color-2)", fontWeight: 600 }}>
                <FontAwesomeIcon
                  icon={faDesktop}
                  className="fa-sm"
                  style={{ marginRight: "1rem", display: "inline-block" }}
                />
                Développement Front
              </li>
              <li style={{ color: "var(--color-3)", fontWeight: 600 }}>
                <FontAwesomeIcon
                  icon={faFileCode}
                  className="fa-sm"
                  style={{ marginRight: "1rem", display: "inline-block" }}
                />
                Intégration Web
              </li>
              <li style={{ color: "var(--color-4)", fontWeight: 600 }}>
                <FontAwesomeIcon
                  icon={faPalette}
                  className="fa-sm"
                  style={{ marginRight: "1rem", display: "inline-block" }}
                />
                UX / UI Design
              </li>
              <li style={{ color: "var(--color-5)", fontWeight: 600 }}>
                <FontAwesomeIcon
                  icon={faTasks}
                  className="fa-sm"
                  style={{ marginRight: "1rem", display: "inline-block" }}
                />
                From Scratch
              </li>
            </ul> */}
          </div>
          <div className="column">
            <ContactForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
